import { scriptData } from '../../fixWebpackPublicPath';
// Check copied from https://gitlab.sas.com/petrichor/sas-vav/blob/35d68e10b67851d00bdb0d380ca646063e4f677f/src/main/java/com/sas/apps/vaviewer/jspsupport/VAViewerSupport.java
const mobileRegex = /android|blackberry|up.browser|up.link|windows ce|ipod|iphone|palm|iemobile|mini|mmp|symbian|midp|wap|phone|pocket|mobile|pda|psp|ipad|silk/;
export function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    return ((userAgent.includes('android') && !userAgent.includes('mobile')) || mobileRegex.test(userAgent));
}
export function displayImagesForMobile() {
    return isMobile() && !!scriptData.imagesOnMobile;
}
