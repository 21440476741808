import { ReactCustomElement } from './ReactCustomElement';
import { createUniqueId } from '../components/utils/createUniqueId';
import { subscribeImportsLoaded, unsubscribeImportsLoaded, getImports } from './dynamicImports';
function getAuthValue(value) {
    if (!value) {
        return;
    }
    switch (value.toLowerCase()) {
        case 'guest':
            return 'guest';
        case 'credentials':
            return 'credentials';
    }
}
export class AbstractReportElement extends ReactCustomElement {
    constructor() {
        super(...arguments);
        /**
         * A unique identifier tied to the lifetime of the custom element.
         * Used to pair report elements with their context and store.
         */
        this._elementKey = createUniqueId();
        this._stateRetained = false;
        this._initialized = false;
        /**
         * Whether the current value of this._handle can be safely given to consumers.
         *
         * A handle is only valid from the time React renders the component until
         * props are changed or the element is removed from the DOM with
         * preserveStateOnUnmount set to false.
         */
        this._isHandleValid = false;
        this._setRef = (ref) => {
            this._handle = ref !== null && ref !== void 0 ? ref : undefined;
        };
    }
    _acceptHandlePromise() {
        var _a;
        this._isHandleValid = true;
        if (this._handle) {
            (_a = this._handlePromiseCallbacks) === null || _a === void 0 ? void 0 : _a.accept(this._handle);
            this._handlePromiseCallbacks = undefined;
        }
        else {
            console.warn('_acceptHandlePromise called when no report handle was available');
        }
    }
    _rejectHandlePromise(reason) {
        var _a;
        // Invalidate report handle.
        // Don't discard this._handle because React might not update the ref in the next render.
        this._isHandleValid = false;
        (_a = this._handlePromiseCallbacks) === null || _a === void 0 ? void 0 : _a.reject(reason);
        this._handlePromiseCallbacks = undefined;
        this._handlePromise = undefined;
    }
    // Note: the user must call this again if they change any property on the custom element
    getReportHandle() {
        if (!this._handlePromise) {
            // If we have a valid handle, resolve immediately.
            // Otherwise wait for the ref to be set during the next render.
            this._handlePromise =
                this._handle && this._isHandleValid
                    ? Promise.resolve(this._handle)
                    : new Promise((accept, reject) => {
                        this._handlePromiseCallbacks = { accept, reject };
                    });
        }
        return this._handlePromise;
    }
    _invalidateProps() {
        // NOTE: if this function changes, _initializeWithImports may need to be updated
        this._rejectHandlePromise('An element attribute was changed and the request for a report handle has been cancelled');
        super._invalidateProps();
    }
    static get observedAttributes() {
        return [...super.observedAttributes, 'url', 'reporturi', 'authenticationtype', 'packageuri'];
    }
    attributeChangedCallback(name, old, value) {
        super.attributeChangedCallback(name, old, value);
        if (name === 'authenticationType' &&
            this.hasAttribute('authenticationType') &&
            !getAuthValue(value)) {
            console.warn(`Invalid AuthenticationType: ${value}`);
        }
    }
    connectedCallback() {
        // Calling _initializeWithImports should "replay" the current state of the WebComponent and pass it to the
        // underlying implementation that gets async loaded.
        subscribeImportsLoaded(this, () => {
            if (this._initialized) {
                return;
            }
            this._initialized = true;
            this._initializeWithImports();
        });
        super.connectedCallback();
    }
    disconnectedCallback() {
        if (!this.preserveStateOnUnmount) {
            // Reject only if preserveStateOnUnmount is false.
            // In OpenUI, the element is briefly disconnected during a rerender. No need to reject in that case.
            this._rejectHandlePromise('The element was removed from the DOM and the request for a report handle has been cancelled');
        }
        if (this._stateRetained) {
            unsubscribeImportsLoaded(this);
        }
        super.disconnectedCallback();
    }
    _initializeWithImports() {
        // Using super instead of this because we don't want to reject ReportHandle
        //  requests that may have been made before the imports were available
        super._invalidateProps();
        const state = this._stateRetained;
        this._stateRetained = false;
        this.preserveStateOnUnmount = state;
    }
    /**
     * A boolean property for controlling the lifetime of state used by this
     * custom element. If false, state will be lost when the element is removed
     * from the DOM. If true, state will be preserved until the property is
     * set back to false.
     *
     * If set to true, the property must be set back to false prior to garbage
     * collection or a large memory leak will occur.
     */
    set preserveStateOnUnmount(value) {
        if (this._stateRetained === !!value) {
            return;
        }
        if (!this._stateRetained && !this._getConnected()) {
            unsubscribeImportsLoaded(this);
        }
        const { extendStoreLifetime, releaseStoreLifetime } = getImports() || {};
        this._stateRetained = !!value;
        if (this._stateRetained) {
            extendStoreLifetime === null || extendStoreLifetime === void 0 ? void 0 : extendStoreLifetime(this._elementKey);
        }
        else {
            releaseStoreLifetime === null || releaseStoreLifetime === void 0 ? void 0 : releaseStoreLifetime(this._elementKey);
            if (!this._getConnected()) {
                this._rejectHandlePromise('preserveStateOnUnmount was set to false while the element was removed from the DOM');
            }
        }
    }
    get preserveStateOnUnmount() {
        return this._stateRetained;
    }
    getCommonProps() {
        if (this.reportUri && this.url) {
            return {
                authenticationType: this.authenticationType,
                reportUri: this.reportUri,
                url: this.url,
                elementKey: this._elementKey,
                ref: this._setRef,
                menuItemProvider: this._menuItemProvider,
            };
        }
        else if (this.packageUri) {
            return {
                packageUri: this.packageUri,
                elementKey: this._elementKey,
                ref: this._setRef,
                menuItemProvider: this._menuItemProvider,
            };
        }
        return null;
    }
    _afterRender() {
        // After render, this._handle should be set, so we can resolve getReportHandle requests
        this._acceptHandlePromise();
    }
    get url() {
        return this.getAttribute('url');
    }
    set url(value) {
        if (value && typeof value === 'string') {
            this.setAttribute('url', value);
        }
        else {
            this.removeAttribute('url');
        }
    }
    get reportUri() {
        return this.getAttribute('reportUri');
    }
    get packageUri() {
        return this.getAttribute('packageUri');
    }
    set packageUri(value) {
        if (value && typeof value === 'string') {
            this.setAttribute('packageUri', value);
        }
        else {
            this.removeAttribute('packageUri');
        }
    }
    set reportUri(value) {
        if (value && typeof value === 'string') {
            this.setAttribute('reportUri', value);
        }
        else {
            this.removeAttribute('reportUri');
        }
    }
    get authenticationType() {
        return getAuthValue(this.getAttribute('authenticationType')) || 'credentials';
    }
    set authenticationType(value) {
        if (value) {
            if (typeof value !== 'string' || !getAuthValue(value)) {
                console.warn(`Invalid AuthenticationType: ${value}`);
                this.removeAttribute('authenticationType');
            }
            else {
                this.setAttribute('authenticationType', value.toLowerCase());
            }
        }
        else {
            this.removeAttribute('authenticationType');
        }
    }
    /**
     * Creates a new key for use in the next render. Used in SASReportElement to
     * force a context to load with new graph css.
     */
    _resetElementKey() {
        var _a, _b;
        this._invalidateProps();
        const oldKey = this._elementKey;
        this._elementKey = createUniqueId();
        if (this._stateRetained) {
            (_b = (_a = getImports()) === null || _a === void 0 ? void 0 : _a.extendStoreLifetime) === null || _b === void 0 ? void 0 : _b.call(_a, this._elementKey);
            setTimeout(() => { var _a, _b; return (_b = (_a = getImports()) === null || _a === void 0 ? void 0 : _a.releaseStoreLifetime) === null || _b === void 0 ? void 0 : _b.call(_a, oldKey); }, 0);
        }
    }
    set menuItemProvider(value) {
        value = value !== null && value !== void 0 ? value : undefined;
        if (value === this._menuItemProvider) {
            return;
        }
        this._invalidateProps();
        this._menuItemProvider = value;
    }
    get menuItemProvider() {
        return this._menuItemProvider;
    }
}
