import { AbstractReportElement } from './AbstractReportElement';
import { getImports } from './dynamicImports';
export class SASReportPageElement extends AbstractReportElement {
    _getRenderer() {
        var _a;
        return (_a = getImports()) === null || _a === void 0 ? void 0 : _a.renderers.SASReportPage;
    }
    static get observedAttributes() {
        return [...super.observedAttributes, 'pagename', 'pageindex'];
    }
    getRenderProps() {
        const commonProps = this.getCommonProps();
        if (!commonProps) {
            return null;
        }
        const index = this.pageIndex;
        if (this.pageName) {
            return Object.assign(Object.assign({}, commonProps), { pageName: this.pageName });
        }
        else if (index !== null) {
            return Object.assign(Object.assign({}, commonProps), { pageIndex: index });
        }
        return null;
    }
    get pageName() {
        return this.getAttribute('pageName');
    }
    set pageName(value) {
        if (value && typeof value === 'string') {
            this.setAttribute('pageName', value);
        }
        else {
            this.removeAttribute('pageName');
        }
    }
    get pageIndex() {
        const attr = this.getAttribute('pageIndex');
        return normalizeNumericInput(attr);
    }
    set pageIndex(value) {
        // @ts-ignore
        const num = normalizeNumericInput(value);
        if (num !== null) {
            this.setAttribute('pageIndex', num.toString());
        }
        else {
            this.removeAttribute('pageIndex');
        }
    }
}
function normalizeNumericInput(value) {
    if (typeof value === 'string' && /^\d+$/.test(value)) {
        return Number(value);
    }
    else if (typeof value === 'number' && value >= 0 && Number.isSafeInteger(value)) {
        return Math.abs(value); // Math.abs is needed to handle -0
    }
    else {
        return null;
    }
}
