// NOTE: only types here. ts 3.8 can make this requirement more explicit...
import { AbstractReportElement } from './AbstractReportElement';
import { getImports } from './dynamicImports';
function validateHideNavigationValue(value) {
    if (value && typeof value === 'string') {
        const lower = value.toLocaleLowerCase();
        return ['true', 'false', 'auto'].indexOf(lower) > -1;
    }
    return true;
}
export class SASReportElement extends AbstractReportElement {
    constructor() {
        super(...arguments);
        this._themeOverrides = null;
    }
    _getRenderer() {
        var _a;
        return (_a = getImports()) === null || _a === void 0 ? void 0 : _a.renderers.SASReport;
    }
    static get observedAttributes() {
        return [...super.observedAttributes, 'hidenavigation', 'parameters'];
    }
    attributeChangedCallback(name, old, value) {
        super.attributeChangedCallback(name, old, value);
        if (name === 'hideNavigation' &&
            this.hasAttribute('hideNavigation') &&
            !validateHideNavigationValue(value)) {
            console.warn(`Invalid value for hideNavigation: ${value}`);
        }
    }
    _initializeWithImports() {
        super._initializeWithImports();
        if (this._unprocessedOverrides) {
            this.unsafe_setCustomReportTheme(this._unprocessedOverrides);
            this._unprocessedOverrides = undefined;
        }
    }
    getRenderProps() {
        const commonProps = this.getCommonProps();
        return (commonProps && Object.assign(Object.assign({}, commonProps), { hideNavigation: this.hideNavigation, themeOverrides: this._themeOverrides || undefined, graphCSS: this._graphCSS }));
    }
    get hideNavigation() {
        if (!this.hasAttribute('hideNavigation'))
            return 'auto';
        const value = this.getAttribute('hideNavigation');
        if (typeof value === 'string') {
            switch (value.toLocaleLowerCase()) {
                case 'true':
                case '':
                    return true;
                case 'false':
                    return false;
                case 'auto':
                default:
                    return 'auto';
            }
        }
        return true;
    }
    set hideNavigation(value) {
        if (typeof value === 'string') {
            if (!validateHideNavigationValue(value)) {
                console.warn(`Invalid value for hideNavigation: ${value}`);
                this.removeAttribute('hideNavigation');
            }
            else {
                this.setAttribute('hideNavigation', value);
            }
        }
        else if (typeof value === 'boolean') {
            this.setAttribute('hideNavigation', value.toString());
            return;
        }
        else if (!value) {
            this.removeAttribute('hideNavigation');
        }
        else {
            console.warn(`Invalid value for hideNavigation: ${value}`);
            this.removeAttribute('hideNavigation');
        }
    }
    unsafe_setCustomReportTheme(value) {
        const imports = getImports();
        if (!imports) {
            this._unprocessedOverrides = value;
            return;
        }
        if (!imports.convertReportTheme) {
            console.error('customReportThemes not supported in this render mode.');
            return;
        }
        let overrides;
        if (typeof value === 'object' && value !== null) {
            const objectValue = value;
            const themeOverride = { baseThemeId: 'light', styleData: {} };
            if (typeof objectValue.baseThemeId === 'string') {
                themeOverride.baseThemeId = objectValue.baseThemeId;
            }
            else {
                console.error('Objects passed to unsafe_setCustomReportTheme must have a string for the baseThemeId property');
            }
            if (typeof objectValue.styleData === 'object' && objectValue.styleData !== null) {
                themeOverride.styleData = objectValue.styleData;
            }
            else {
                console.error('Objects passed to unsafe_setCustomReportTheme must have an object for the styleData property');
            }
            overrides = imports.convertReportTheme(themeOverride);
        }
        else if (value === null || value === undefined) {
            overrides = null;
        }
        else {
            overrides = null;
            console.error('Invalid value passed to unsafe_setCustomReportTheme: ' + value);
        }
        if (this._themeOverrides === overrides) {
            return;
        }
        this._themeOverrides = overrides;
        this._invalidateProps();
    }
    unsafe_setGraphCSS(value) {
        let graphCSS;
        if (typeof value === 'string') {
            graphCSS = value;
        }
        else if (value === null || value === undefined) {
            graphCSS = undefined;
        }
        else {
            graphCSS = undefined;
            console.error('Invalid value passed to unsafe_setGraphCSS: ' + value);
        }
        if (this._graphCSS === graphCSS) {
            return;
        }
        this._graphCSS = graphCSS;
        // Force a new report context to be created so the css gets applied
        this._resetElementKey();
        this._invalidateProps();
    }
}
