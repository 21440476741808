import { createElement } from 'react';
import * as ReactDOM from 'react-dom';
export class ReactCustomElement extends HTMLElement {
    constructor() {
        super(...arguments);
        this._validationTimerId = 0;
        /**
         * We can't use isConnected from the Node class (https://developer.mozilla.org/en-US/docs/Web/API/Node/isConnected)
         * The custom element polyfill we use for Edge support does not set it (ever).
         *
         * FIXME: once we drop non-Chromium Edge support, we can use isConnected instead.
         */
        this._connected = false;
        this._render = () => {
            const renderer = this._getRenderer();
            if (!renderer) {
                ReactDOM.unmountComponentAtNode(this);
                return;
            }
            const props = this._connected && this.getRenderProps();
            // Block invalidation on getRenderProps
            clearTimeout(this._validationTimerId);
            this._validationTimerId = 0;
            if (props) {
                ReactDOM.render(createElement(renderer, props), this, () => this._afterRender());
            }
            else {
                ReactDOM.unmountComponentAtNode(this);
            }
        };
    }
    _getConnected() {
        return this._connected;
    }
    _invalidateProps() {
        if (this._connected && this._validationTimerId === 0) {
            this._validationTimerId = window.setTimeout(this._render, 0);
        }
    }
    connectedCallback() {
        this._connected = true;
        this._render();
    }
    disconnectedCallback() {
        this._connected = false;
        this._render();
    }
    //
    // This callback gets called anytime an observed attribute is changed.
    //
    attributeChangedCallback(_name, _old, _value) {
        this._invalidateProps();
    }
    //
    // Subclasses should override to return their own attribute list.
    // That list should include the observed attributes of the super class.
    //
    static get observedAttributes() {
        return [];
    }
}
