import { AbstractReportElement } from './AbstractReportElement';
import { getImports } from './dynamicImports';
export class SASReportObjectElement extends AbstractReportElement {
    _getRenderer() {
        var _a;
        return (_a = getImports()) === null || _a === void 0 ? void 0 : _a.renderers.SASReportObject;
    }
    static get observedAttributes() {
        return [...super.observedAttributes, 'objectname'];
    }
    getRenderProps() {
        const commonProps = this.getCommonProps();
        if (commonProps && this.objectName) {
            return Object.assign(Object.assign({}, commonProps), { objectName: this.objectName });
        }
        else {
            return null;
        }
    }
    get objectName() {
        return this.getAttribute('objectName');
    }
    set objectName(value) {
        if (value && typeof value === 'string') {
            this.setAttribute('objectName', value);
        }
        else {
            this.removeAttribute('objectName');
        }
    }
}
