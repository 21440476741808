import { isOpenUI } from './utils/isOpenUI';
let pubPath = '';
const dataProps = {};
(function setVaSdkWebpackPublicPath() {
    var _a, _b;
    let oldWebpackPath = '';
    try {
        oldWebpackPath = __webpack_public_path__;
    }
    catch (_c) {
        // thrown when __webpack_public_path__ isn't defined
        console.warn('va-sdk:WARN: did not set webpack public path');
        return;
    }
    function setWebpackPathRelativeToScript(src, scriptEl) {
        const newWebpackPath = src.substring(0, src.lastIndexOf('/') + 1);
        pubPath = newWebpackPath;
        __webpack_public_path__ = newWebpackPath;
        console.info(`va-sdk:INFO: set __webpack_public_path__ from "${oldWebpackPath}" to "${newWebpackPath}" using "${src}"`);
        if (scriptEl === null || scriptEl === void 0 ? void 0 : scriptEl.dataset.imagesOnMobile) {
            dataProps.imagesOnMobile = scriptEl === null || scriptEl === void 0 ? void 0 : scriptEl.dataset.imagesOnMobile;
        }
    }
    // if OpenUI, use the standard module resolution
    if (isOpenUI) {
        const scriptSrc = (_b = (_a = window.jQuery) === null || _a === void 0 ? void 0 : _a.sap) === null || _b === void 0 ? void 0 : _b.getResourcePath('sas/vasdk/lib/vaReportComponents.js');
        if (scriptSrc) {
            setWebpackPathRelativeToScript(scriptSrc);
            return;
        }
    }
    // if currentScript is not null, then this script is **currently being processed** and is not an es6 module.
    if (document.currentScript) {
        const scriptSrc = document.currentScript.src;
        if (scriptSrc) {
            setWebpackPathRelativeToScript(scriptSrc, document.currentScript);
            return;
        }
    }
    // if document.currentScript didn't work, try to find our <script> tag
    const scriptSrcPattern = /\/va-report-components.js$/;
    const scriptTags = document.getElementsByTagName('script'), scriptTagsLength = scriptTags.length;
    for (let i = 0; i < scriptTagsLength; ++i) {
        const script = scriptTags[i], scriptSrc = script.src;
        if (scriptSrcPattern.test(scriptSrc)) {
            setWebpackPathRelativeToScript(scriptSrc, script);
            return;
        }
    }
    console.warn('va-sdk:WARN: did not set webpack public path');
})();
// #endregion webpack public path set
export const publicPath = pubPath;
export const scriptData = dataProps;
