import { __rest } from "tslib";
// isMobile and loadCss should be the only non-type import.
import { displayImagesForMobile } from './isMobile';
import { whenCssLoaded } from '../../loadCss';
function firedImportsLoadedCallbacks() {
    if (elementsToInvalidate) {
        for (const callback of elementsToInvalidate.values()) {
            callback();
        }
    }
    elementsToInvalidate = undefined;
}
if (displayImagesForMobile()) {
    Promise.all([import('./mobileImportsImpl'), whenCssLoaded()]).then(([impl]) => {
        imports = {
            renderers: impl.renderers,
            convertReportTheme: undefined,
            extendStoreLifetime: undefined,
            releaseStoreLifetime: undefined,
        };
        firedImportsLoadedCallbacks();
    });
}
else {
    Promise.all([import('./dynamicImportsImpl'), whenCssLoaded()]).then(([imp]) => {
        const { convertReportTheme, extendStoreLifetime, releaseStoreLifetime, initNovaI18n } = imp, renderers = __rest(imp, ["convertReportTheme", "extendStoreLifetime", "releaseStoreLifetime", "initNovaI18n"]);
        imports = {
            renderers,
            convertReportTheme,
            extendStoreLifetime,
            releaseStoreLifetime,
        };
        initNovaI18n();
        firedImportsLoadedCallbacks();
    });
}
let imports;
let elementsToInvalidate = new Map();
/**
 * Returns the dynamic imports if they have not already been loaded. If the dynamic imports have not been loaded
 * undefined will be returned.
 */
export function getImports() {
    return imports;
}
/**
 * Registers a callback that is fired when the dynamic imports have loaded.
 *
 * A callback new callback will override the old callback for an element associated with the callback has already been
 * registered.
 *
 * @param key for tracking the callback owner
 * @param cb the callback function to be called
 */
export function subscribeImportsLoaded(key, cb) {
    elementsToInvalidate === null || elementsToInvalidate === void 0 ? void 0 : elementsToInvalidate.set(key, cb);
}
/**
 * Removes the associated callback from the subscribe list.
 * @see subscribeImportsLoaded
 * @param key callback owner
 */
export function unsubscribeImportsLoaded(key) {
    elementsToInvalidate === null || elementsToInvalidate === void 0 ? void 0 : elementsToInvalidate.delete(key);
}
